import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import { motion, useInView, useAnimation, AnimatePresence } from 'framer-motion';
import Slider from 'react-slick';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faAngular, faNode, faAws, faWordpress, faShopify, faDocker, faWix, faSquarespace, faFigma } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import './websiteapp.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Galleryimag1 from './rcs.webp';
import Galleryimag2 from './18 (2).webp';
import Galleryimag3 from './simple.webp';
import Galleryimag4 from './4.webp';
import Galleryimag5 from './5.webp';
import Galleryimag6 from './11.webp';
import Galleryimag7 from './7.webp';
import Galleryimag8 from './8.webp';
import Galleryimag9 from './pathlinkport.webp';
import Galleryimag10 from './9.webp';
import Galleryimag11 from './10.webp';
import Galleryimag12 from './19 (3).webp';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';


gsap.registerPlugin(ScrollTrigger);

function WebsiteApp() {
  const navigate = useNavigate();
  const navigateToWebPricing = () => navigate('/pricing');

  const images = [
    Galleryimag1,
    Galleryimag3,
    Galleryimag4,
    Galleryimag2,
    Galleryimag5,
    Galleryimag6,
    Galleryimag7,
    Galleryimag8,
    Galleryimag9,
    Galleryimag10,
    Galleryimag11,
    Galleryimag12,
  ];

  return (
    <>
      <Header navigateToWebPricing={navigateToWebPricing} />
      <Features />
      <InfiniteCarousel />
      <RecentWork images={images} />
      <FAQSection />
      <HomeContact />
    </>
  );
}
gsap.registerPlugin(ScrollTrigger);

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const wordVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -60 : 60,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.3,
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const Header = () => {
  const component = useRef();
  const scrollDownRef = useRef();
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.icon-animation', {
        y: -10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        duration: 1,
      });

    
    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={component}>
      <section className="header-section about-container">
        <Container className="text-center">
          <motion.div
            className="text-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div className="word" custom="left" variants={wordVariants}>
              WEBSITE
            </motion.div>
            <motion.div className="word" custom="right" variants={wordVariants}>
              <span>
                <span className='highlight'>D</span>EVELOPMENT
              </span>
            </motion.div>
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span>
                <span className='highlight'>S</span>ERVICE<span className='highlight'>S</span>
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            className="learn-more"
            initial="hidden"
            animate="visible"
            variants={iconVariants}
            ref={scrollDownRef}
          >
            <div className="circle-icon icon-animation">
              <FontAwesomeIcon icon={faArrowDown} size="lg" />
            </div>
            <span className="who-we">LEARN MORE</span>
          </motion.div>
        </Container>
      </section>
    </div>
  );
};


const Features = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <section className="py-5" id="features">
      <Container className="my-5">
      <div className="custom-services-subheader">
          <h3>Key Features</h3>
          <span className="custom-line-icon"></span>
        </div>
        <div className="custom-wwo-section mt-3 feature-accordian-1">
          <h2 className='feature-margin'>Web Development</h2>
        </div>
        <div className=" mt-3 mb-5 feature-accordian-1">
          
        </div>
        <motion.div
          className="py-5 scrollable-section"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Accordion activeKey={activeKey} className="feature-accordion">
            {accordionItems.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header onClick={() => handleToggle(index.toString())}>
                  <span>{item.title}</span>
                  <div className="accordion-icon">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="icon-circle"
                      style={{
                        transform: activeKey === index.toString() ? 'rotate(45deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <p>{item.description}</p>
                  <ul>
                    {item.points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </motion.div>
      </Container>
    </section>
  );
};

const accordionItems = [
  {
    title: 'User Interface & User Experience',
    description: 'Boost user satisfaction and engagement with our tailored UI/UX designs:',
    points: [
      'Analyze user behaviors to create intuitive navigation and interactive elements.',
      'Develop customized user personas that resonate deeply with each segment of your audience.',
      'Implement inclusive design standards to ensure accessibility for all users.',
    ],
  },
  {
    title: 'Security & Data Protection',
    description: 'Secure your online operations with our robust protection strategies:',
    points: [
      'Deploy advanced encryption and firewall systems to safeguard sensitive information.',
      'Utilize strong authentication methods to control access and protect user data.',
      'Perform continuous security assessments to strengthen your website’s defenses.',
    ],
  },
  {
    title: 'SEO & Optimization Services',
    description: "Improve your website's visibility and performance with our comprehensive SEO and optimization services:",
    points: [
      'Image and code optimization to significantly reduce load times and enhance SEO rankings.',
      'Implementation of content delivery networks (CDNs) for fast, reliable access for users worldwide.',
      'Integration of modern technologies to boost website responsiveness and efficiency, ensuring superior search engine performance.',
      'Strategic keyword usage to target relevant search terms and increase organic traffic.',
    ],
  },
  {
    title: 'Scalability & Maintainability',
    description: 'Build a foundation for growth with our scalable web solutions:',
    points: [
      'Architect your site to handle increased traffic and expanding business needs smoothly.',
      'Integrate effective version control to facilitate updates and team collaboration.',
      'Design your infrastructure to easily adapt to new challenges and opportunities.',
    ],
  },
  {
    title: 'Technological Integration',
    description: 'Expand your site’s capabilities with seamless integration of cutting-edge technologies:',
    points: [
      'Enhance your site\'s functionality with APIs for services like e-commerce, social media, and analytics.',
      'Regularly update software frameworks to leverage the latest advancements.',
      'Customize tech solutions based on real-time feedback and evolving business goals.',
    ],
  },
  {
    title: 'Maintenance & Support',
    description: 'Ensure your website operates smoothly with our comprehensive maintenance and support services:',
    points: [
      'Regularly update and patch software to keep your site secure and running efficiently.',
      'Provide 24/7 support to address any issues or concerns promptly.',
      'Monitor website performance and uptime to prevent any disruptions.',
    ],
  },
];



const InfiniteCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const technologies = [
    { 
      name: 'React', 
      description: 'We utilize React to build dynamic and responsive web applications. Its component-based architecture allows for efficient development and high performance.',
      icon: faReact
    },
    { 
      name: 'Angular', 
      description: 'Our team uses Angular for building robust multi-page applications. Angular’s powerful tools and libraries ensure a always seamless user experience and maintainability.',
      icon: faAngular
    },
    { 
      name: 'Node.js', 
      description: 'For backend development, we leverage Node.js to build scalable and fast server-side applications. Its event-driven architecture and non-blocking I/O are ideal for high-performance apps.',
      icon: faNode
    },
    { 
      name: 'AWS', 
      description: 'We utilize AWS to ensure our web applications are scalable, secure, and reliable. With services like EC2, S3, and Lambda, we build applications that can handle large-scale traffic and data.',
      icon: faAws
    },
    { 
      name: 'WordPress', 
      description: 'When it comes to content management systems, WordPress is our top pick for crafting versatile and effortlessly manageable websites. With its robust plugin ecosystem, we can enhance functionalities.',
      icon: faWordpress
    },
    { 
      name: 'Shopify', 
      description: 'For e-commerce solutions, we use Shopify to build robust online stores. Shopify’s platform allows us to create user-friendly, scalable, and secure e-commerce websites.',
      icon: faShopify
    },
    { 
      name: 'Docker', 
      description: 'We use Docker for containerization, ensuring that our applications run consistently across different environments. Docker enables us to deploy applications with ease and reliability.',
      icon: faDocker
    },
    { 
      name: 'Wix', 
      description: 'We rely on Wix to craft versatile and user-friendly websites, utilizing its extensive array of customizable templates and features to ensure your online presence stands out effortlessly.',
      icon: faWix
    },
    { 
      name: 'Squarespace', 
      description: 'Squarespace stands out as our preferred platform for crafting visually stunning and polished websites. Its intuitive interface empowers seamless customization, ensuring your site reflects your unique vision with ease.',
      icon: faSquarespace
    },
    { 
      name: 'GoDaddy', 
      description: 'We prefer GoDaddy for its trusted hosting solutions and seamless domain management services, which guarantee both speed and security for our websites.',
      icon: faServer
    },
    { 
      name: 'Figma', 
      description: 'Figma is our go-to tool for designing user interfaces. It allows for real-time collaboration and streamlined design workflows.',
      icon: faFigma
    },
    { 
      name: 'Vercel', 
      description: 'Vercel is our platform of choice for deploying fast and modern web applications, ensuring seamless integration and optimal performance.',
      icon: faServer
    },
  ];

  return (
    <section className="carousel-section">
      <div className="carousel-background-text">
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
      </div>
      <div className="tech-can">
        <div className="carousel-header">
          <h2>TECHNOLOGIES WE USE</h2>
          <p>We offer innovative services based on your business needs:</p>
        </div>
        <Slider {...settings}>
          {technologies.map((tech, index) => (
            <div key={index} className="carousel-card">
              <div className="carousel-card-content">
                <FontAwesomeIcon icon={tech.icon} size="3x" />
                <h3>{tech.name}</h3>
                <p>{tech.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const RecentWork = ({ images }) => {
  const containerRefTop = useRef(null);
  const containerRefBottom = useRef(null);
  const isInViewTop = useInView(containerRefTop, { once: true });
  const isInViewBottom = useInView(containerRefBottom, { once: true });
  const lastScrollY = useRef(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) return;

      const currentScrollY = window.scrollY;

      if (containerRefTop.current && containerRefBottom.current) {
        if (currentScrollY > lastScrollY.current) {
          containerRefTop.current.scrollLeft += 2;
          containerRefBottom.current.scrollLeft -= 2;
        } else {
          containerRefTop.current.scrollLeft -= 2;
          containerRefBottom.current.scrollLeft += 2;
        }
      }
      lastScrollY.current = currentScrollY;
    };

    if (isInViewTop || isInViewBottom) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInViewTop, isInViewBottom, isMobile]);

  useEffect(() => {
    if (containerRefBottom.current) {
      containerRefBottom.current.scrollLeft = containerRefBottom.current.scrollWidth; // Start at the far right
    }
  }, []);

  return (
    <section className="py-5 work-bg scrollable-section">
      <div className="full-width">
        <div className="p-5 rounded-3">
          <div className="custom-services-subheader">
            <h1 className="display-4 explore-head">Explore Our Recent Projects</h1>
            <p className='large-screen-hide'>Take a look at some of the amazing work we've done for our clients. We pride ourselves on delivering high-quality projects that drive results.</p>
          </div>
          <div className="image-gallery-container" ref={containerRefTop}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Web development project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
          <div className="image-gallery-container mt-2" ref={containerRefBottom}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Web development project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = () => (
  <motion.section 
    className="py-5 faq-dark-bg" 
    id="faq" 
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
    viewport={{ once: true, amount: 0.3 }}
  >
    <div className="container px-5">
      <div className="custom-services-subheader">
        <h3>FAQ Section</h3>
        <span className="custom-line-icon"></span>
      </div>
      <div className="custom-wwo-section mt-3 faq-section-1">
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingOne">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
              What services do you offer to enhance my business website?
            </button>
          </h3>
          <div id="collapseOne" className="accordion-collapse collapse"
               aria-labelledby="headingOne">
            <div className="accordion-body">
              We specialize in user experience and interface design, security and data protection, performance optimization, scalability, and technological integration. Each service is designed to meet specific business needs, ensuring that your website not only looks professional but also performs excellently under various conditions.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingtwo">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsetwo"
                    aria-expanded="false" aria-controls="collapsetwo">
              Can you explain your pricing structure for web development services?
            </button>
          </h3>
          <div id="collapsetwo" className="accordion-collapse collapse"
               aria-labelledby="headingtwo">
            <div className="accordion-body">
              Our pricing is based on the complexity of the project and the specific services you need. We offer packages that can be customized to fit a range of budgets, and we always provide detailed quotations before starting work. For specific pricing, contact us directly for a consultation.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingthree">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsethree"
                    aria-expanded="false" aria-controls="collapseOne">
              What measures do you take to ensure the security of my website?
            </button>
          </h3>
          <div id="collapsethree" className="accordion-collapse collapse"
               aria-labelledby="headingthree">
            <div className="accordion-body">
              We implement industry-standard security measures including HTTPS, data encryption, regular security audits, and compliance with data protection regulations. Our proactive approach ensures that your website is safeguarded against various cyber threats and vulnerabilities.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfour">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefour"
                    aria-expanded="false" aria-controls="collapsefour">
                How do you handle website maintenance and updates?
            </button>
          </h3>
          <div id="collapsefour" className="accordion-collapse collapse"
               aria-labelledby="headingfour">
            <div className="accordion-body">
              We offer ongoing support and maintenance packages that include regular updates to software and security patches, monitoring website performance, and making adjustments as needed. Our goal is to ensure your website remains current with the latest technology and continues to function smoothly over time.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfive">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefive"
                    aria-expanded="false" aria-controls="collapsefive">
              How can I get a quote for my website project?
            </button>
          </h3>
          <div id="collapsefive" className="accordion-collapse collapse"
               aria-labelledby="headingfive">
            <div className="accordion-body">
            To receive a customized quote, click the 'Web Development' pricing page on the navbar or contact us directly through our Contact Us page. We are eager to assist you in bringing your app idea to life.
            </div>
          </div>
        </div>
      </div>
    </div>
  </motion.section>
);

const HomeContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    projectType: '',
    message: ''
  });

  const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const formAppearVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.3, duration: 0.5, ease: 'easeInOut' },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch('https://formspree.io/f/xwkgdeaa', {
        method: 'POST',
        body: new FormData(form),
        headers: { Accept: 'application/json' },
      });

      const data = await response.json();
      if (data.ok) {
        setSubmitted(true);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  return (
    <Container fluid className="custom-container help-me">
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6} xl={6} xxl={6} className="left-column">
          <h2 className="homeform-heading">Get in Touch</h2>
          <p className="contact-text1 small-text">
            We are a skilled team delivering custom websites, apps, graphic designs, and marketing solutions for your business.
          </p>
          <div className="contact-info">
            
            <p className="contact-detail-item email-detail">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
              </span>
              <span className="contact-detail">hello@divinesoftwaresystems.com</span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
              </span>
              <span className="contact-detail">
                US Office:{' '}
                <a
                  href="https://www.google.com/maps/place/Divine+Software+Systems+LLC/@37.5763866,-121.979915,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc13cb689ed09:0x2abc072afed6bcb8!8m2!3d37.5763866!4d-121.9773401!16s%2Fg%2F11y5l0w8my?entry=ttu"
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                  Fremont, CA
                </a>
              </span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
              </span>
              <span className="contact-detail">
                PH Office:{' '}
                <a
                  href="https://www.google.com/maps/place/BM+ONE+office+and+commercial+building/@14.4916087,120.9937898,17z/data=!4m16!1m9!3m8!1s0x3397cff3448fe381:0xf4f092d112e2d74f!2sBM+ONE+office+and+commercial+building!8m2!3d14.4916041!4d120.9937636!9m1!1b1!16s%2Fg%2F11txlr0tdy?entry=ttu"
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                  Manila, PH
                </a>
              </span>
            </p>
          </div>
        </Col>
        <Col sm={12} lg={6} xl={6} xxl={6}>
          <AnimatePresence mode="wait">
            {submitted ? (
              <motion.div
                key="thank-you"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={handleSubmitVariants}
                className="paper-like-container text-center thank-you-message"
              >
                <h3 className="thank-you-title">Thank you for reaching out!</h3>
                <p className="thank-you-text">
                  Your message has been successfully submitted.
                </p>
                <p className="thank-you-text">
                  We appreciate your interest and will get back to you promptly.
                </p>
                <div className="email-response">
                  <h4 className="response-title">In the meantime, here's what to expect:</h4>
                  <ul className="response-list">
                    <li>Confirmation email has been sent to your provided address.</li>
                    <li>Your inquiry is being reviewed by our team of experts.</li>
                    <li>We will respond to your message within 24-48 hours.</li>
                  </ul>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="form"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                variants={formAppearVariants}
                className="paper-like-container"
              >
                <h2 className="form-header">Make an Appointment</h2>
                <Form className="centered-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          className="paper-like-input"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          className="paper-like-input"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                          className="paper-like-input"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          className="paper-like-input"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Project Type</Form.Label>
                    <Form.Control
                      as="select"
                      className="paper-like-input"
                      name="projectType"
                      value={formData.projectType}
                      onChange={handleChange}
                    >
                      <option value="">Select Web Service</option>
                      <option value="Website Design">Website Design</option>
                      <option value="Website Development">Website Development</option>
                      <option value="E-commerce Development">E-commerce Development</option>
                      <option value="Maintenance & Support">Maintenance & Support</option>
                      <option value="Custom Web Application">Custom Web Application</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Please provide details about your web development needs."
                      className="paper-like-input"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ fontWeight: '550', minWidth: '100px', marginBottom: '-10px' }}
                  >
                    Submit
                  </Button>
                </Form>
              </motion.div>
            )}
          </AnimatePresence>
        </Col>
      </Row>
    </Container>
  );
};

export default WebsiteApp;